import React from "react";

const RightArrowIcon = () => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="40" height="40" rx="6" fill="#FAFAFA" />
    <path
      d="M16.5 27.5L23.5 20.5L16.5 13.5"
      stroke="#9DA5B3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default RightArrowIcon;
