import React from "react";
type OwnProps = {
  h: number;
  w: number;
};
const HelloCard = ({ h, w }: OwnProps) => {
  return (
    <img
      src="/HelloProfileCard.png"
      alt="Hello Cards"
      width={`${w}px`}
      height={`${h}}px`}
    />
  );
};

export default HelloCard;
