import React from "react";
type OwnProps = {
  h: number;
  w: number;
};
const GooglePlayDownload = ({ h, w }: OwnProps) => {
  return (
    <a href="https://play.google.com/store/apps/details?id=io.helloprofile.android">
      <img
        src="/googleplay-download.png"
        alt="Download App on GooglePlay"
        width={`${w}px`}
        height={`${h}}px`}
      />
    </a>
  );
};

export default GooglePlayDownload;
