import React from "react";
import { Button, ButtonProps, Link } from "@nextui-org/react";

interface OwnPropTypes extends ButtonProps {
  text: string;
  linkTo?: string;
}

function HelloButton(props: OwnPropTypes) {
  return props.linkTo ? (
    <Link href={props.linkTo}>
      <Button
        {...props}
        style={{
          backgroundColor: "#00C2FF",
          color: "#ffffff",
          padding: "20px 40px 20px 40px",
        }}
      >
        {props.text}
      </Button>
    </Link>
  ) : (
    <Button
      {...props}
      style={{
        backgroundColor: "#00C2FF",
        color: "#ffffff",
        padding: "20px 40px 20px 40px",
      }}
    >
      {props.text}
    </Button>
  );
}

export default HelloButton;
