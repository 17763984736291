import React from "react";
import "./App.css";
import OrderCard from "./features/order-card/OrderCard";
import Header from "./components/Header";
import styled from "styled-components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import OrderConfirmation from "./features/order-card/OrderConfirmation";

function App() {
  return (
    <>
      <Header />
      <MainContainer>
        <Router>
          <Routes>
            <Route path="/" element={<OrderCard />} />

            <Route path="/order-confirmed" element={<OrderConfirmation />} />
            <Route path="/get-the-app" element={<OrderConfirmation />} />
          </Routes>
        </Router>
      </MainContainer>
    </>
  );
}

export default App;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 20px;
`;
