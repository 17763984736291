import React from "react";

export default function SuccessIcon() {
  return (
    <svg
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9655 3.92647C23.667 1.95587 27.332 1.95587 30.0335 3.92647C31.3542 4.88985 32.9474 5.40751 34.5821 5.4044C37.9259 5.39803 40.891 7.55227 41.9182 10.7344C42.4204 12.2901 43.4051 13.6453 44.7294 14.6037C47.4383 16.564 48.5709 20.0496 47.5315 23.2278C47.0234 24.7815 47.0234 26.4567 47.5315 28.0105C48.5709 31.1887 47.4383 34.6743 44.7294 36.6346C43.4051 37.5929 42.4204 38.9482 41.9182 40.5039C40.891 43.686 37.9259 45.8402 34.5821 45.8339C32.9474 45.8308 31.3542 46.3484 30.0335 47.3118C27.332 49.2824 23.667 49.2824 20.9655 47.3118C19.6449 46.3484 18.0516 45.8308 16.4169 45.8339C13.0731 45.8402 10.1081 43.686 9.08082 40.5039C8.57863 38.9482 7.59397 37.5929 6.26962 36.6346C3.56068 34.6743 2.42813 31.1887 3.46748 28.0105C3.97559 26.4567 3.97559 24.7815 3.46748 23.2278C2.42813 20.0496 3.56068 16.564 6.26963 14.6037C7.59397 13.6453 8.57863 12.2901 9.08082 10.7344C10.1081 7.55227 13.0731 5.39803 16.4169 5.4044C18.0516 5.40751 19.6449 4.88985 20.9655 3.92647Z"
        fill="#32C87D"
      />
      <path
        d="M17.3076 25.3433L23.5214 31.8439L34.454 20.8961"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
