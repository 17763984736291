import * as Yup from "yup";

// Validation Schema
export const checkoutSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  confirmEmail: Yup.string()
    .required("Please confirm your email")
    .test("emails-match", "Emails must match", function (value) {
      return this.parent.email === value;
    }),
  country: Yup.string().required("Country is required"),
  city: Yup.string().required("City/Province is required"),
  street: Yup.string().required("Street Name and Number is required"),
  postalCode: Yup.string().required("Postal Code is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(/^\+\d{1}/, "Phone number must start with +XX country code")
    .matches(/^\+\d{1}[0-9]{10,14}$/, "Phone number is not valid"),
});
