import { Card, CardBody, CardHeader } from "@nextui-org/react";
import styled from "styled-components";
import HelloCard from "../../components/HelloCard";

function ProductCard() {
  return (
    <ProductCardContainer>
      <Card
        style={{
          borderRadius: "16px",
          border: "1px solid #DDD",
          background: "rgba(245, 245, 245, 0.96)",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          width: "346.242px",
          height: "494.593px",
          padding: "20px",
        }}
      >
        <CardHeader style={{ justifyContent: "center" }}>
          <p style={{ fontWeight: "700", fontSize: "16px", color: "#555" }}>
            HelloCard
          </p>
        </CardHeader>
        <CardBody style={{ alignItems: "center" }}>
          <HelloCard h={283} w={179} />
          <p
            style={{
              fontWeight: "700",
              fontSize: "24px",
              color: "#555",
              marginTop: "20px",
            }}
          >
            €19.99
          </p>
          <p
            style={{
              fontWeight: "500",
              fontSize: "10px",
              color: "#555",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            Pre-order the HelloCard now
            <br />
            and get free shipping.
          </p>
        </CardBody>
      </Card>
    </ProductCardContainer>
  );
}

export default ProductCard;

const ProductCardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
