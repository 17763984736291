import React, { useEffect, useState } from "react";
import { Modal, ModalContent, ModalBody } from "@nextui-org/react";
import styled from "styled-components";
import HelloCard from "../../components/HelloCard";
import HelloInput from "../../components/Form/HelloInput";
import LocationIcon from "../../components/Icons/LocationIcon";
import RightArrowIcon from "../../components/Icons/RightArrowIcon";
import HelloButton from "../../components/HelloButton";
import { checkUsername, registerUser } from "./api";
import { debounce } from "lodash";
import password from "secure-random-password";

interface OwnProps {
  handleClose: () => void;
  formValues: {
    firstName: string;
    lastName: string;
    email: string;
    country: "Finland";
    city: string;
    street: string;
    postalCode: string;
    phoneNumber: string;
  };
}
const ConfirmOrder = (props: OwnProps) => {
  const [isUserNameAvailable, setIsUserNameAvailable] =
    useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [isUsernameFieldDisabled, setIsUsernameFieldDisabled] = useState(false);
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const checkUsernameAvailability = async () => {
      setIsLoading(true);
      const email = props.formValues.email;
      const checkUserResult = await checkUsername(email);
      const isUserNameAvailable = checkUserResult.isUsernameAvailable;
      if (!isUserNameAvailable) {
        const username = checkUserResult.currentUsername;
        setUsername(username);
        setIsUsernameFieldDisabled(true);
        setIsExistingUser(true);
      }
      setIsLoading(false);
    };
    checkUsernameAvailability();
  }, [props.formValues.email]);
  const { handleClose, formValues } = props;
  const fullAddress = `${formValues.street}, ${formValues.city}, ${formValues.postalCode}, ${formValues.country}`;

  const checkUsernameAvailability = debounce(async (username: string) => {
    const userNameAvailability = (await checkUsername(username))
      .isUsernameAvailable;
    if (userNameAvailability !== undefined) {
      setIsUserNameAvailable(userNameAvailability);
    }
  }, 500);

  const onUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUsername = event.currentTarget.value.replace(/\s+/g, "");
    setUsername(newUsername);
    checkUsernameAvailability(newUsername);
  };

  const getUsernameStatus = () => {
    if (isExistingUser) {
      return (
        <p
          style={{
            fontSize: "10px",
            fontWeight: "400",
            color: "green",
          }}
        >
          Awesome! You're an existing HelloProfile user. Welcome back!
        </p>
      );
    }
    if (isUserNameAvailable) {
      return (
        <p
          style={{
            fontSize: "10px",
            fontWeight: "400",
            color: getTextColor(isUserNameAvailable),
          }}
        >
          This username is available!
        </p>
      );
    }

    if (!isUserNameAvailable) {
      return (
        <p
          style={{
            fontSize: "10px",
            fontWeight: "400",
            color: getTextColor(isUserNameAvailable),
          }}
        >
          This username is taken, please try another!
        </p>
      );
    }
    return null;
  };

  const handleSubmission = async () => {
    setIsButtonDisabled(true);
    setOrderStatus("Please wait...");
    if (!isExistingUser) {
      try {
        await registerUser({
          username: username,
          password: password.randomPassword(),
          email: formValues.email,
          firstname: formValues.firstName,
          lastname: formValues.lastName,
          city: formValues.city,
          country: formValues.country,
          postalcode: formValues.postalCode,
          street: formValues.street,
          phone: formValues.phoneNumber,
        });
      } catch (error) {
        alert("Something went wrong, please try again or contact our support.");
      }
    }

    window.location.href = "https://buy.stripe.com/14k9CW8lm6Wp2ic4gg";
  };

  return (
    <Modal isOpen={true} onClose={handleClose} size="3xl" placement="center">
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody style={{ padding: "0px" }}>
              <ConfirmOrderContainer>
                <ProductCardContainer>
                  <HelloCard h={282} w={178} />
                </ProductCardContainer>
                <ConfirmOrderFormContainer>
                  <h1 style={{ fontWeight: "700", fontSize: "24px" }}>
                    Confirm Order
                  </h1>
                  <p style={typeStyle}>Custom username</p>
                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#0A8CFA",
                      wordWrap: "break-word",
                    }}
                  >
                    https://www.helloprofile.io/profile/
                    {username.length > 8 && <br />}
                    <b>{username}</b>
                  </p>
                  {username !== "" && (
                    <p
                      style={{
                        fontSize: "10px",
                        fontWeight: "400",
                        color: getTextColor(isUserNameAvailable),
                      }}
                    >
                      {getUsernameStatus()}
                    </p>
                  )}
                  {isUsernameFieldDisabled ||
                  isLoading ||
                  orderStatus !== "" ? (
                    <HelloInput
                      isDisabled={true}
                      type="text"
                      value={
                        isLoading
                          ? "Checking for existing account..."
                          : username
                      }
                    />
                  ) : (
                    <HelloInput
                      onChange={onUserNameChange}
                      type="text"
                      placeholder="Enter your username"
                      value={username}
                    />
                  )}
                  <p style={{ fontSize: "10px", fontWeight: "400" }}>
                    This will be your default username, so make it easy to
                    remember.
                  </p>
                  <p style={typeStyle}>Delivery Address</p>
                  <ThreeColumnGroup onClick={handleClose}>
                    <LocationIcon />
                    <p style={{ fontWeight: "400", fontSize: "14px" }}>
                      {fullAddress}
                    </p>
                    <RightArrowIcon />
                  </ThreeColumnGroup>
                  <p
                    style={{
                      fontWeight: "300",
                      fontSize: "10px",
                    }}
                  >
                    By placing an order for HelloCard, you are agreeing to our{" "}
                    <a
                      style={{ color: "rgb(10, 140, 250)" }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://flame-scaffold-26c.notion.site/Privacy-Policy-Terms-of-Agreement-HelloProfile-io-aee864b5126e4befacd4ab53b71a27f7"
                    >
                      <b>Privacy Policy and Terms of Service</b>
                    </a>
                    .
                  </p>
                  <HelloButton
                    isDisabled={
                      isButtonDisabled || username === "" || orderStatus !== ""
                    }
                    onPress={handleSubmission}
                    text={orderStatus !== "" ? orderStatus : "Place Order"}
                  />
                </ConfirmOrderFormContainer>
              </ConfirmOrderContainer>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ConfirmOrder;

const ConfirmOrderContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  @media (max-width: 670px) {
    flex-direction: column;
    align-items: center;
    padding: 20px; // Centers items when in column layout
  }
`;
const ProductCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px;
  background-color: #bfecfa;
`;
const ConfirmOrderFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 50px 20px 50px 20px;
`;
const typeStyle = {
  fontWeight: "500",
  fontSize: "14px",
};

const ThreeColumnGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: 5px;
`;
const getTextColor = (isUserAvailable: boolean) =>
  isUserAvailable ? "green" : "red";
