import React from "react";

function LocationIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 2.16211C8.3 2.16211 4.5 5.38211 4.5 10.3621C4.5 13.5421 6.95 17.2821 11.84 21.5921C12.22 21.9221 12.79 21.9221 13.17 21.5921C18.05 17.2821 20.5 13.5421 20.5 10.3621C20.5 5.38211 16.7 2.16211 12.5 2.16211ZM12.5 12.1621C11.4 12.1621 10.5 11.2621 10.5 10.1621C10.5 9.06211 11.4 8.16211 12.5 8.16211C13.6 8.16211 14.5 9.06211 14.5 10.1621C14.5 11.2621 13.6 12.1621 12.5 12.1621Z"
        fill="#00C2FF"
      />
    </svg>
  );
}

export default LocationIcon;
