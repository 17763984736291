import React from "react";
export const HelloProfileLogo = () => (
  <a href="https://helloprofile.io">
    <svg
      width="167"
      height="33"
      viewBox="0 0 167 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.7407 18.236V24.5H40.5007V8.5H43.7407V14.1752C44.7199 13.1816 45.8143 12.6848 47.024 12.6848C48.248 12.6848 49.2776 13.1168 50.1128 13.9808C50.948 14.8448 51.3656 15.9968 51.3656 17.4368V24.5H48.1256V18.1496C48.1256 16.364 47.4704 15.4712 46.16 15.4712C45.5119 15.4712 44.9431 15.7088 44.4535 16.184C43.9783 16.6448 43.7407 17.3288 43.7407 18.236Z"
        fill="#555555"
      />
      <path
        d="M64.6715 22.6558C63.3611 23.8942 61.8203 24.5134 60.0491 24.5134C58.2922 24.5134 56.8378 23.9734 55.6858 22.8934C54.5338 21.799 53.9578 20.3374 53.9578 18.5086C53.9578 16.6798 54.541 15.2254 55.7074 14.1454C56.8882 13.0654 58.2778 12.5254 59.8763 12.5254C61.4747 12.5254 62.8283 13.0078 63.9371 13.9726C65.0603 14.9374 65.6219 16.2622 65.6219 17.947V19.675H57.1546C57.2554 20.3086 57.5866 20.827 58.1482 21.2302C58.7098 21.6334 59.3434 21.835 60.0491 21.835C61.1867 21.835 62.1227 21.4534 62.8571 20.6902L64.6715 22.6558ZM62.3387 17.3638C62.2667 16.6726 62.0075 16.1398 61.5611 15.7654C61.1147 15.391 60.5675 15.2038 59.9194 15.2038C59.2858 15.2038 58.7026 15.3982 58.1698 15.787C57.637 16.1758 57.3274 16.7014 57.241 17.3638H62.3387Z"
        fill="#555555"
      />
      <path d="M71.5408 24.5H68.3008V8.5H71.5408V24.5Z" fill="#555555" />
      <path d="M77.9373 24.5H74.6973V8.5H77.9373V24.5Z" fill="#555555" />
      <path
        d="M83.8124 18.5302C83.8124 19.4806 84.0788 20.2582 84.6116 20.863C85.1588 21.4534 85.8501 21.7486 86.6853 21.7486C87.5349 21.7486 88.2261 21.4534 88.7589 20.863C89.3061 20.2582 89.5797 19.4806 89.5797 18.5302C89.5797 17.5798 89.3061 16.8022 88.7589 16.1974C88.2261 15.5926 87.5349 15.2902 86.6853 15.2902C85.8501 15.2902 85.1588 15.5926 84.6116 16.1974C84.0788 16.8022 83.8124 17.5798 83.8124 18.5302ZM92.8414 18.5302C92.8414 20.215 92.2582 21.6334 91.0917 22.7854C89.9253 23.9374 88.4565 24.5134 86.6853 24.5134C84.9285 24.5134 83.4668 23.9374 82.3004 22.7854C81.134 21.6334 80.5508 20.215 80.5508 18.5302C80.5508 16.8454 81.134 15.427 82.3004 14.275C83.4668 13.1086 84.9285 12.5254 86.6853 12.5254C88.4565 12.5254 89.9253 13.1086 91.0917 14.275C92.2582 15.427 92.8414 16.8454 92.8414 18.5302Z"
        fill="#555555"
      />
      <path
        d="M102.225 12.8457C103.579 12.8457 104.781 13.4145 105.832 14.5521C106.898 15.6753 107.431 17.0937 107.431 18.8073C107.431 20.5209 106.898 21.9537 105.832 23.1057C104.781 24.2577 103.543 24.8337 102.117 24.8337C100.706 24.8337 99.5683 24.2649 98.7043 23.1273V28.5313H95.4642V13.0401H98.7043V14.3361C99.6979 13.3425 100.872 12.8457 102.225 12.8457ZM98.6611 18.8721C98.6611 19.8081 98.9203 20.5785 99.4387 21.1833C99.9715 21.7737 100.612 22.0689 101.361 22.0689C102.11 22.0689 102.765 21.7737 103.327 21.1833C103.888 20.5785 104.169 19.8081 104.169 18.8721C104.169 17.9361 103.896 17.1513 103.348 16.5177C102.801 15.8841 102.146 15.5673 101.383 15.5673C100.62 15.5673 99.9715 15.8841 99.4387 16.5177C98.9203 17.1369 98.6611 17.9217 98.6611 18.8721Z"
        fill="#555555"
      />
      <path
        d="M116.179 15.7311C115.214 15.7311 114.494 16.0767 114.019 16.7679C113.544 17.4447 113.306 18.3447 113.306 19.4679V24.5007H110.066V12.8799H113.306V14.4135C113.724 13.9383 114.242 13.5351 114.862 13.2039C115.495 12.8727 116.136 12.6999 116.784 12.6855L116.806 15.7311H116.179Z"
        fill="#555555"
      />
      <path
        d="M121.586 18.5302C121.586 19.4806 121.852 20.2582 122.385 20.863C122.932 21.4534 123.623 21.7486 124.459 21.7486C125.308 21.7486 126 21.4534 126.532 20.863C127.08 20.2582 127.353 19.4806 127.353 18.5302C127.353 17.5798 127.08 16.8022 126.532 16.1974C126 15.5926 125.308 15.2902 124.459 15.2902C123.623 15.2902 122.932 15.5926 122.385 16.1974C121.852 16.8022 121.586 17.5798 121.586 18.5302ZM130.615 18.5302C130.615 20.215 130.032 21.6334 128.865 22.7854C127.699 23.9374 126.23 24.5134 124.459 24.5134C122.702 24.5134 121.24 23.9374 120.074 22.7854C118.907 21.6334 118.324 20.215 118.324 18.5302C118.324 16.8454 118.907 15.427 120.074 14.275C121.24 13.1086 122.702 12.5254 124.459 12.5254C126.23 12.5254 127.699 13.1086 128.865 14.275C130.032 15.427 130.615 16.8454 130.615 18.5302Z"
        fill="#555555"
      />
      <path
        d="M136.914 12.5608V13.3384H139.722V15.6712H136.914V24.5312H133.653V15.6712H132.292V13.3384H133.653V12.6256C133.653 11.3584 134.034 10.3576 134.797 9.6232C135.575 8.8744 136.533 8.5 137.67 8.5C138.822 8.5 139.866 8.932 140.802 9.796L139.636 11.9776C139.161 11.4304 138.635 11.1568 138.059 11.1568C137.728 11.1568 137.454 11.2792 137.238 11.524C137.022 11.7544 136.914 12.1 136.914 12.5608Z"
        fill="#555555"
      />
      <path
        d="M145.195 24.5312H141.955V13.3072H145.195V24.5312ZM142.214 11.7616C141.854 11.3872 141.674 10.9336 141.674 10.4008C141.674 9.868 141.854 9.4216 142.214 9.0616C142.588 8.6872 143.042 8.5 143.575 8.5C144.107 8.5 144.554 8.6872 144.914 9.0616C145.288 9.4216 145.475 9.868 145.475 10.4008C145.475 10.9336 145.288 11.3872 144.914 11.7616C144.554 12.1216 144.107 12.3016 143.575 12.3016C143.042 12.3016 142.588 12.1216 142.214 11.7616Z"
        fill="#555555"
      />
      <path
        d="M151.717 24.4998H148.477V8.47266H151.717V24.4998Z"
        fill="#555555"
      />
      <path
        d="M165.152 22.6421C163.842 23.8805 162.301 24.4997 160.53 24.4997C158.773 24.4997 157.318 23.9597 156.166 22.8797C155.014 21.7853 154.438 20.3237 154.438 18.4949C154.438 16.6661 155.021 15.2117 156.188 14.1317C157.369 13.0517 158.598 12.5117 160.197 12.5117C161.795 12.5117 163.309 12.9941 164.418 13.9589C165.541 14.9237 166.103 16.2485 166.103 17.9333V19.6613H157.635C157.736 20.2949 158.067 20.8133 158.629 21.2165C159.19 21.6197 159.824 21.8213 160.53 21.8213C161.667 21.8213 162.603 21.4397 163.338 20.6765L165.152 22.6421ZM162.819 17.3501C162.747 16.6589 162.488 16.1261 162.042 15.7517C161.595 15.3773 161.048 15.1901 160.4 15.1901C159.766 15.1901 159.183 15.3845 158.65 15.7733C158.117 16.1621 157.808 16.6877 157.721 17.3501H162.819Z"
        fill="#555555"
      />
      <path
        d="M7.94922 16.7384C8.79951 16.2476 9.88676 16.5389 10.3777 17.3891C11.0018 18.4701 11.8994 19.3679 12.9804 19.9919C14.0614 20.616 15.2877 20.9446 16.5359 20.9446C17.7842 20.9446 19.0105 20.616 20.0914 19.9919C21.1724 19.3679 22.07 18.4701 22.6942 17.3891C23.1851 16.5389 24.2723 16.2476 25.1226 16.7384C25.973 17.2295 26.2642 18.3167 25.7733 19.1669C24.8371 20.7883 23.4907 22.1349 21.8691 23.071C20.2476 24.0072 18.4082 24.5 16.5359 24.5C14.6636 24.5 12.8242 24.0072 11.2027 23.071C9.58121 22.1349 8.2347 20.7883 7.29853 19.1669C6.80762 18.3167 7.09894 17.2295 7.94922 16.7384Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5002 0.5C7.66355 0.5 0.5 7.66344 0.5 16.5C0.5 25.3365 7.66355 32.5 16.5002 32.5H30.9005C31.7842 32.5 32.5005 31.7837 32.5005 30.9V16.5C32.5005 7.66344 25.3369 0.5 16.5002 0.5ZM10.3777 17.3891C9.8868 16.5389 8.79955 16.2476 7.94926 16.7384C7.09898 17.2294 6.80766 18.3166 7.29857 19.1669C8.23474 20.7883 9.58126 22.1349 11.2028 23.071C12.8242 24.0072 14.6636 24.5 16.5359 24.5C18.4083 24.5 20.2477 24.0072 21.8691 23.071C23.4907 22.1349 24.8372 20.7883 25.7733 19.1669C26.2642 18.3166 25.973 17.2294 25.1226 16.7384C24.2724 16.2476 23.1851 16.5389 22.6943 17.3891C22.0701 18.4701 21.1725 19.3678 20.0915 19.9918C19.0105 20.616 17.7843 20.9446 16.5359 20.9446C15.2877 20.9446 14.0615 20.616 12.9805 19.9918C11.8995 19.3678 11.0018 18.4701 10.3777 17.3891Z"
        fill="#00C2FF"
      />
    </svg>
  </a>
);
