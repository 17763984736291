import styled from "styled-components";
import SuccessIcon from "../../components/Icons/SuccessIcon";
import DownloadAppQRCode from "../../components/DownloadAppQRCode";
import AppStoreDownload from "../../components/AppStoreDownload";
import GooglePlayDownload from "../../components/GooglePlayDownload";
import { useLocation } from "react-router-dom";

export default function OrderConfirmation() {
  const location = useLocation();

  return (
    <OrderConfirmationContainer>
      {location.pathname === "/order-confirmed" && (
        <>
          <SuccessIcon />
          <p
            style={{ fontWeight: "500", fontSize: "14px", textAlign: "center" }}
          >
            Thank you for your order.
            <br /> You will receive email confirmation shortly.
          </p>{" "}
        </>
      )}

      <AppDownloadContainer>
        <DownloadDescription>
          <h3 style={{ fontSize: "20px", fontWeight: "700" }}>
            HelloProfile App
          </h3>
          <p
            style={{ fontWeight: "500", fontSize: "14px", textAlign: "center" }}
          >
            While you await your HelloCard, use the links to download the
            HelloProfile Mobile Application to start building your profile.
          </p>
        </DownloadDescription>
        <QRCodeContainer>
          <p style={{ fontWeight: "500", fontSize: "14px" }}>
            Scan here to download
          </p>
          <DownloadAppQRCode h={250} w={250} />
        </QRCodeContainer>
        <DownloadButtons>
          <p style={{ fontSize: "12px", fontWeight: "700" }}>Download Links</p>
          <AppStoreDownload h={60} w={200} />
          <GooglePlayDownload h={60} w={200} />
        </DownloadButtons>
      </AppDownloadContainer>
    </OrderConfirmationContainer>
  );
}

const AppDownloadContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 100px;
  margin-top: 50px;
  @media (max-width: 895px) {
    flex-direction: column;
    row-gap: 50px;
  }
`;
const DownloadDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-wrap: wrap;
  max-width: 200px;
  justify-content: center;
  align-items: center;
`;

const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const DownloadButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

const OrderConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
`;
