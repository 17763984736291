import React from "react";
type OwnProps = {
  h: number;
  w: number;
};
const AppStoreDownload = ({ h, w }: OwnProps) => {
  return (
    <a href="https://apps.apple.com/us/app/helloprofile/id1661098509">
      <img
        src="/appstore-download.png"
        alt="Download App on AppStore"
        width={`${w}px`}
        height={`${h}}px`}
      />
    </a>
  );
};

export default AppStoreDownload;
