import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import styled from "styled-components";
import HelloInput from "../../components/Form/HelloInput";
import HelloButton from "../../components/HelloButton";
import ProductCard from "./ProductCard";
import LocationIcon from "../../components/Icons/LocationIcon";
import PaymentIcon from "../../components/Icons/PaymentIcon";
import ConfirmOrder from "./ConfirmOrder";
import { checkoutSchema } from "./schema";

const initialFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  country: "Finland",
  city: "",
  street: "",
  postalCode: "",
  phoneNumber: "",
};

function Checkout() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(() => {
    const saved = sessionStorage.getItem("formData");
    return saved ? JSON.parse(saved) : initialFormValues;
  });

  useEffect(() => {
    sessionStorage.setItem("formData", JSON.stringify(formValues));
  }, [formValues]);

  const handleModalClose = () => setModalOpen(false);

  const handlePlaceOrder = (values: Object) => {
    setModalOpen(true);
    setFormValues(values);
  };

  return (
    <Formik
      initialValues={formValues}
      validationSchema={checkoutSchema}
      onSubmit={handlePlaceOrder}
    >
      <Form>
        <CheckoutContainer>
          <ProductCard />
          <ParentContainer>
            <div>
              <IconSection>
                <LocationIcon />
                <h3 style={{ fontWeight: "700", fontSize: "24px" }}>
                  Delivery Address
                </h3>
              </IconSection>
              <FormColumnGroup>
                <Field
                  as={HelloInput}
                  name="street"
                  label="Street Name and Number"
                  placeholder="Street Name and Number"
                  isRequired
                />
                <ErrorMessage name="street" component={StyledErrorMessage} />
                <Field
                  as={HelloInput}
                  name="city"
                  label="City/Province"
                  placeholder="City/Province"
                  isRequired
                />
                <ErrorMessage name="city" component={StyledErrorMessage} />
                <Field
                  as={HelloInput}
                  name="postalCode"
                  label="Postal Code"
                  placeholder="Postal Code"
                  isRequired
                />
                <ErrorMessage
                  name="postalCode"
                  component={StyledErrorMessage}
                />
                <Field
                  as={HelloInput}
                  name="country"
                  label="Country"
                  value="Finland"
                  disabled
                />
              </FormColumnGroup>
            </div>
            <div>
              <IconSection>
                <PaymentIcon />
                <h3 style={{ fontWeight: "700", fontSize: "24px" }}>
                  Your Information
                </h3>
              </IconSection>
              <FormColumnGroup>
                <InputGroup>
                  <div>
                    <Field
                      as={HelloInput}
                      name="firstName"
                      label="First name"
                      placeholder="First name"
                      isRequired
                    />
                    <ErrorMessage
                      name="firstName"
                      component={StyledErrorMessage}
                    />
                  </div>
                  <div>
                    <Field
                      as={HelloInput}
                      name="lastName"
                      label="Last name"
                      placeholder="Last name"
                      isRequired
                    />
                    <ErrorMessage
                      name="lastName"
                      component={StyledErrorMessage}
                    />
                  </div>
                </InputGroup>
                <Field
                  as={HelloInput}
                  name="email"
                  label="Email"
                  placeholder="yourmail@gmail.com"
                  isRequired
                />
                <ErrorMessage name="email" component={StyledErrorMessage} />
                <Field
                  as={HelloInput}
                  name="confirmEmail"
                  label="Confirm Email"
                  placeholder="yourmail@gmail.com"
                  isRequired
                />
                <ErrorMessage
                  name="confirmEmail"
                  component={StyledErrorMessage}
                />
                <Field
                  as={HelloInput}
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="+358...."
                  isRequired
                />
                <ErrorMessage
                  name="phoneNumber"
                  component={StyledErrorMessage}
                />
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "10px",
                    color: "#737373",
                  }}
                >
                  This number will be used for the HelloCard delivery updates.
                </p>
                <HelloButton
                  type="submit"
                  text="Confirm Order"
                  style={{ width: "100%" }}
                />
              </FormColumnGroup>
            </div>
          </ParentContainer>
          {isModalOpen && (
            <ConfirmOrder
              handleClose={handleModalClose}
              formValues={formValues}
            />
          )}
        </CheckoutContainer>
      </Form>
    </Formik>
  );
}

export default Checkout;

const StyledErrorMessage = styled.div`
  font-size: 10px;
  color: red;
`;
const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  column-gap: 70px;
  @media (max-width: 1025px) {
    flex-direction: column;
    align-items: center; // Centers items when in column layout
    gap: 30px;
  }
`;
const InputGroup = styled.div`
  display: flex;
  flex-direction: row;

  gap: 10px;
`;
const ParentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
  padding: 20px;
  @media (max-width: 1025px) {
    flex-direction: column;
  }
`;

const FormColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1; // This ensures that each FormColumnGroup takes up an equal amount of space
`;
const IconSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
`;
