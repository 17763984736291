import React from "react";
import { Input, InputProps } from "@nextui-org/react";
import styled from "styled-components";

const HelloInput = (props: InputProps) => (
  <HelloInputContainer>
    <Input {...props} fullWidth {...props} labelPlacement="outside" />
  </HelloInputContainer>
);

export default HelloInput;

const HelloInputContainer = styled.div``;
