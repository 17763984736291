import axios from "axios";

export const checkUsername = async (username: string) => {
  try {
    const response = await axios.get(
      `https://api.helloprofile.io/api/v1/user/${username}/check`
    );

    const currentUsername = response.data.responseDetails.username;
    return { isUsernameAvailable: false, currentUsername };
  } catch (error: any) {
    if (error.response.status === 404) {
      return { isUsernameAvailable: true };
    }
  }
  alert("Something went wrong, please again or contact HelloProfile support");
};

export const registerUser = async (userDetails) => {
  try {
    await axios.post("https://api.helloprofile.io/api/v1/auth/user", {
      ...userDetails,
    });
  } catch (error) {
    console.log(error);
  }
};
