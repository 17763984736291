import React from "react";
type OwnProps = {
  h: number;
  w: number;
};
const DownloadAppQRCode = ({ h, w }: OwnProps) => {
  return (
    <img
      src="/app-download-qr-code.png"
      alt="QR Code to download the HelloProfile App"
      width={`${w}px`}
      height={`${h}}px`}
    />
  );
};

export default DownloadAppQRCode;
