import React from "react";

function PaymentIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 4H4.5C3.39 4 2.51 4.89 2.51 6L2.5 18C2.5 19.11 3.39 20 4.5 20H20.5C21.61 20 22.5 19.11 22.5 18V6C22.5 4.89 21.61 4 20.5 4ZM19.5 18H5.5C4.95 18 4.5 17.55 4.5 17V12H20.5V17C20.5 17.55 20.05 18 19.5 18ZM20.5 8H4.5V7C4.5 6.45 4.95 6 5.5 6H19.5C20.05 6 20.5 6.45 20.5 7V8Z"
        fill="#00C2FF"
      />
    </svg>
  );
}

export default PaymentIcon;
