import { Navbar, NavbarBrand } from "@nextui-org/react";
import React from "react";
import { HelloProfileLogo } from "./HelloProfileLogo";
import HelloButton from "./HelloButton";

const Header = () => {
  return (
    <Navbar>
      <NavbarBrand>
        <HelloProfileLogo />
      </NavbarBrand>
      <HelloButton text="Get the App" linkTo="/get-the-app" />
    </Navbar>
  );
};

export default Header;
